@import 'bootstrap/dist/css/bootstrap.min.css';
body{margin-top:20px;}
.product-rating {
    line-height: 22px;
}
.product-rating .yellow {
    color: #ffa900;
    display: inline-block;
    margin-right: 2px;
    font-size: 14px;
}
.btn {
    background-color:#c7004d;
    color: #fff;
    margin-top:13px;
}
.whatsapp_float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 5px;
    background-color: #c7004d;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
    box-shadow: inset 0 0 2px rgb(0 0 0 / 3%), 0 4px 10px rgb(0 0 0 / 17%);
    border-color: transparent;
}

.whatsapp_float i{
    font-size: 20px!important;
    color: #fff;
    transform: translateZ(0);
   
}
.btn:hover{
    background-color: #c7004d;
    color: #fff;
    margin-top: 13px;
}
.product-rating .black{
    color: #5f5d5d;
    display: inline-block;
    margin-right: 2px;
    font-size: 14px;
}
.maxhightclass{
    max-height: 570px;
    overflow: auto;
}
@media screen and (max-width: 1199px) {
    .product-rating {
        margin-bottom: 5px;
        font-size: 15px;
    }
}
@media screen and (max-width: 767px) {
    .product-rating i {
        font-size: 13px;
    }
}
.text-secondary {
    color: #004975 !important;
}
.card-wrapper {
    position: relative;
    overflow: hidden;
}
.card-wrapper .card-img {
    text-align: center;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease-out;
}
.card-wrapper .card-img img {
    transition: all 0.3s ease-in-out;
    border-radius: 0.25rem;
    border-radius: 0.25rem;
}
.card-wrapper .card-body {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    visibility: hidden;
    padding: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
    transform: translateX(-100%);
    transition: 0.8s;
    z-index: 11;
}
.card-wrapper:before {
    content: "";
    width: 100%;
    position: absolute;
    height: 100%;
    top: 0;
    right: 100%;
    z-index: 9;
    transition: 0.8s;
    background: rgba(0, 186, 238, 0.82);
    border-radius: 0.25rem;
}
.card-wrapper:hover:before {
    right: 0;
}
.card-wrapper h3,
.card-wrapper p {
    color: #fff;
}
.card-wrapper .read-more {
    color: #fff;
}
.card-wrapper .read-more:after {
    color: #fff;
}
.card-wrapper:hover .card-body {
    visibility: visible;
    transform: translateX(0px);
}
.card-wrapper:hover .backgound-color {
    right: 0;
}
.ti-shopping-cart:before {
    content: "\e60d";
}
.mb-2-6, .my-2-6 {
    margin-bottom: 2.6rem;
}
.text-primary {
    color: #00baee !important;
}

.proceed_to_cart{
  background: #B81930 0% 0% no-repeat padding-box;
  border: 1px solid #B81930;
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  color: #fff;
  margin-top: 8px;
}
.removeitem{
  float: right;
  margin-top: 3px;
}
.d-flex {
    display: flex!important;
    align-content: center;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}


.sliding-panel-container {
    position: fixed;
    top: 0;
    left: 0;
  }
  
  .sliding-panel-container.active {
    position: fixed;
    z-index: 15000;
    background-color: rgba(0, 0, 0, 0.3);
    will-change: transform;
  }
  
  .sliding-panel-container.active.click-through {
    pointer-events: none;
    background-color: transparent;
  }
  
  .sliding-panel-container.click-through > div {
    pointer-events: none;
  }
  
  .panel-content {
    height: 100%;
    width: 100%;
    pointer-events: all;
    background-color: #fff;
  }
  
  .panel-container-left-enter {
    opacity: 0;
    transform: translateX(-100%);
  }
  .panel-container-left-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: transform 500ms, opacity 500ms;
  }
  .panel-container-left-exit {
    opacity: 1;
    transform: translateX(0);
  }
  .panel-container-left-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: transform 500ms, opacity 500ms;
  }
  
  .panel-container-right-enter {
    opacity: 0;
    transform: translateX(100%);
  }
  .panel-container-right-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: transform 500ms, opacity 500ms;
  }
  .panel-container-right-exit {
    opacity: 1;
    transform: translateX(0);
  }
  .panel-container-right-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: transform 500ms, opacity 500ms;
  }
  
  .panel-container-top-enter {
    opacity: 0;
    transform: translateY(-100%);
  }
  .panel-container-top-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: transform 500ms, opacity 500ms;
  }
  .panel-container-top-exit {
    opacity: 1;
    transform: translateX(0);
  }
  .panel-container-top-exit-active {
    opacity: 0;
    transform: translateY(-100%);
    transition: transform 500ms, opacity 500ms;
  }
  
  .panel-container-bottom-enter {
    opacity: 0;
    transform: translateY(100%);
  }
  .panel-container-bottom-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: transform 500ms, opacity 500ms;
  }
  .panel-container-bottom-exit {
    opacity: 1;
    transform: translateX(0);
  }
  .panel-container-bottom-exit-active {
    opacity: 0;
    transform: translateY(100%);
    transition: transform 500ms, opacity 500ms;
  }
 
.quantity {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.mycartpopup{
  padding: 13px;
  background: #EF5D72 0% 0% no-repeat padding-box;
  border: 1px solid #EF5D72;
  opacity: 1;
  color: #fff;
  font-size: 25px;
}
.closebutton{
  background: transparent;
  border: navajowhite;
  color: #fff;
}
.closespan{
  float: right;
}
.card {
  background: #fff;
  background: #F6F6F6 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 5px #00000029;
  border: 1px solid #F6F6F6;
  border-radius: 6px;
  opacity: 1;
}
.card-title{
 text-align: left;
 font: normal normal normal 14px/17px Proxima Nova;
 letter-spacing: 0px;
 color: #000000;
}
.card-body {
  flex: 1 1 auto;
  padding: 7px 7px;
}
.card-img-top {
  width: 100%;
  max-height: 180px;
  object-fit: contain;
}